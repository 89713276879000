import React from "react";
import LocalizedLink from "./LocalizedLink";
import styled from "styled-components";
import facebookLogo from "../assets/facebook.svg";
import instagramLogo from "../assets/instagram.svg";
import youtubeLogo from "../assets/youtube.svg";
import whatsappLogo from "../assets/whatsappLogo.svg";
import { useStringsContext } from "../contexts/strings-context";

const socialLinks = [
    {
        logo: facebookLogo,
        name: "Facebook",
        link: "https://www.facebook.com/varqaschoolandjrcollege"
    },
    {
        logo: instagramLogo,
        name: "Instagram",
        link: "https://www.instagram.com/varqaschool"
    },
    {
        logo: whatsappLogo,
        name: "Whatsapp",
        link: "https://wa.me/+919822408237"
    },
    {
        logo: youtubeLogo,
        name: "YouTube",
        link: "https://www.youtube.com/channel/UCP8kqceqqNt5DxjOeo-q7eA"
    }
];

const Container = styled.footer`
    display: flex;
    flex-flow: column nowrap;
    margin: 0 auto;
    max-width: 100%;
    background: #383838;
    color: rgba(255, 255, 255, 0.8);

    a,
    a:visited {
        color: rgba(255, 255, 255, 0.8);
        text-decoration: none;
        :hover {
            color: #c6a26d;
        }
    }
`;

const LandingRoot = styled(Container)`
    max-width: 100%;
    display: flex;
    flex: 1;
    margin: 0 auto;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 89rem) {
        flex-flow: column nowrap;
        #contact-legal-privacy {
            order: 3;
        }
    }
`;

const TextContainer = styled.div`
    margin: 0.5rem 15rem;
    font-family: "Play";
    @media (max-width: 50rem) {
        margin: 0.5rem 0.5rem;
    }
    font-size: 1.1em;
`;

const FlexRow = styled.div`
    display: flex;
    flex: 1;
    margin: 0 0.5rem;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 55rem) {
        flex-flow: column nowrap;
        margin-top: -0.5rem;
    }
    padding: 0.5rem 0;
`;

const SocialContainer = styled.div`
    display: flex;
    flex: 1;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
    margin: 0.5rem -0.5rem;
    a {
        margin: 0 1.5rem;
    }
`;

const SocialImg = styled.img`
    height: 1.5rem;
    width: 1.5rem;
    margin: 0;
    display: block;
`;

const Footer: React.FC = () => {
    const strings = useStringsContext();
    return (
        <LandingRoot>
            <FlexRow>
                <TextContainer
                    id="contact-legal-privacy"
                    style={{ flex: 1, justifyContent: "center" }}
                >
                    <LocalizedLink href="/contact">{strings.get("contact")}</LocalizedLink>
                    <span>&ensp;|&ensp;</span>
                    <LocalizedLink href="/legal">{strings.get("legal")}</LocalizedLink>
                    <span>&ensp;|&ensp;</span>
                    <LocalizedLink href="/legal/#privacy">{strings.get("privacy")}</LocalizedLink>
                </TextContainer>
            </FlexRow>
            <FlexRow>
                <SocialContainer style={{ flex: 1, justifyContent: "center" }}>
                    {socialLinks.map(({ name, link, logo }) => (
                        <a key={name} href={link} target="_blank">
                            <SocialImg src={logo} alt={name} />
                        </a>
                    ))}
                </SocialContainer>
            </FlexRow>
        </LandingRoot>
    );
};

export default Footer;
