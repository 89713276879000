import { withTheme } from "./src/theme";
import { withStringsContext } from "./src/contexts/strings-context";
import { withPageContext } from "./src/contexts/page-context";
import { Layout } from "./src/components/layout";
import { createElement } from "react";
import { withErrorBoundary } from "./src/utils/ErrorBoundary";

const wrapHoc = (component, withHoc) => withHoc(component);

export const wrapPageElement = ({ element, props }) => {
    const { strings = {} } = props?.data ?? {};
    const page = [
        withErrorBoundary,
        withTheme,
        withStringsContext(strings),
        withPageContext
    ].reduce(wrapHoc, Layout);
    return createElement(page, props, element);
};
