import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";
export const GlobalStyle = createGlobalStyle`
    ${reset}
    html {
      font-size: 16px;
      scroll-behavior: smooth;
    }
    body {
       -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        margin: 0;
        background-image: linear-gradient(to right bottom, #06255f, #0d2356, #12214d, #141f45, #161d3c, #212245, #2c284e, #372d56, #573970, #7e4286, #a84998, #d54ea4);
        background-repeat: no-repeat;
        color: whitesmoke;
    }
`;
