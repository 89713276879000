import {
    useRef,
    useState,
    useEffect,
    MutableRefObject,
    Dispatch,
    SetStateAction
} from "react";

type UseOutsideAlert = (
    initialValue?: boolean
) => {
    visible: boolean;
    setVisible: Dispatch<SetStateAction<boolean>>;
    ref: MutableRefObject<HTMLLIElement | null>;
};
export const useOutsideAlert: UseOutsideAlert = (initialValue = false) => {
    const ref = useRef<HTMLLIElement | null>(null);
    const [visible, setVisible] = useState<boolean>(initialValue);

    const handleClickOutside = (event: MouseEvent) => {
        if (!(event.target instanceof Element)) return;
        if (ref.current && !ref.current.contains(event.target))
            setVisible(false);
    };
    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, [ref]);
    return { visible, setVisible, ref };
};
