import React from "react";
import styled from "styled-components";
import VarqaschoolSVG from "../assets/svg/varqaschool";
import MobileNavBar from "./mobileNavbar";
import LocalizedLink from "./LocalizedLink";

const FirstNavList = styled.div`
    background: transparent;
    padding: 0;
    border-radius: 0.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;
`;
const SecondNavList = styled(FirstNavList)`
    flex-flow: row nowrap;
    @media (max-width: 1432px) {
        display: none;
    }
`;
const SecondMobileNavList = styled(FirstNavList)`
    @media (min-width: 1432px) {
        display: none;
    }
`;
const NavContainer = styled.nav`
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
`;

const Title = styled.h1`
    font-size: 2.5rem;
    font-family: "Cinzel";
    color: #BA884B;
    letter-spacing: 1px;
    text-align: center;
    line-height: 2.5rem;
`;

const NavItem = styled.div`
    list-style: none;
    margin: 1rem;
    color: white;
`;
const LinkItem = styled(LocalizedLink)`
    color: #b8b5b5;
    :hover {
        color: #BA884B;
    }
    text-decoration: none;
    font-family: "Roboto";
    font-weight: 400;
    font-size: 1.2em;
`;
const SVG = styled(VarqaschoolSVG)`
    width: 10rem;
    height: 10rem;
`;

export const Navbar: React.FC = () => {
    return (
        <>
            <NavContainer>
                <FirstNavList>
                <NavItem>
                    <LinkItem href ="/">
                        <SVG/>
                    </LinkItem>
                </NavItem>
                <NavItem>
                    <LinkItem href ="/">
                        <Title>VARQA SCHOOL &amp; JR. COLLEGE</Title>
                    </LinkItem>
                </NavItem>
                </FirstNavList>
                <SecondMobileNavList>
                    <MobileNavBar/>
                </SecondMobileNavList>
                <SecondNavList>
                <NavItem>
                    <LinkItem href ="/about">About</LinkItem>
                </NavItem>
                <NavItem>
                    <LinkItem href ="https://docs.google.com/forms/d/e/1FAIpQLSdAdWNcTwUp5u17kFvL09aycm4zNvf80Bamz28OSntbjjp-2A/viewform?usp=sf_link" target="_blank">Admission</LinkItem>
                </NavItem>
                <NavItem>
                    <LinkItem href ="/enquire">Online Enquiry</LinkItem>
                </NavItem>
                <NavItem>
                    <LinkItem href ="/cocurricular">Co-curricular</LinkItem>
                </NavItem>
                <NavItem>
                    <LinkItem href ="/achievements">Achievements</LinkItem>
                </NavItem>
                <NavItem>
                    <LinkItem href ="/testimonies">Testimonies</LinkItem>
                </NavItem>
                <NavItem>
                    <LinkItem href ="/academics">Academics</LinkItem>
                </NavItem>
                <NavItem>
                    <LinkItem href ="/events">Events</LinkItem>
                </NavItem>
                <NavItem>
                    <LinkItem href ="/yearlyplanner">Yearly Planner</LinkItem>
                </NavItem>
                <NavItem>
                    <LinkItem href ="/gallery">Gallery</LinkItem>
                </NavItem>
                <NavItem>
                    <LinkItem href ="/contact">Contact</LinkItem>
                </NavItem>
                </SecondNavList>
            </NavContainer>
        </>
    );
};