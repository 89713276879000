import React, { FunctionComponent } from "react";

type ErrorBoundaryState = {
    error: Error | null;
    hasError: boolean;
    info: React.ErrorInfo | null;
};

class ErrorBoundary extends React.Component<
    Record<string, never>,
    ErrorBoundaryState
> {
    constructor(props = {}) {
        super(props);
        this.state = {
            hasError: false,
            error: null,
            info: null
        };
    }

    componentDidCatch(error: Error, info: React.ErrorInfo): void {
        this.setState({
            hasError: true,
            error: error,
            info: info
        });
        if (typeof window !== "undefined" && window.gtag) {
            window.gtag("event", "exception", {
                description: `Javascript Error: ${this.state.error?.toString()}\n\nStack Trace: ${this.state.info?.componentStack}`
            });
        }
    }

    render(): React.ReactNode {
        if (this.state.hasError) {
            return (
                <div>
                    <h1>Something went wrong :(</h1>
                </div>
            );
        }
        return this.props.children;
    }
}

export { ErrorBoundary };

type WithErrorBoundary = (Component: FunctionComponent) => FunctionComponent;

export const withErrorBoundary: WithErrorBoundary = (Component) => (props) => (
    <ErrorBoundary>
        <Component {...props} />
    </ErrorBoundary>
);
