exports.components = {
  "component---src-pages-languages-json-language-404-tsx": () => import("./../../../src/pages/{languagesJson.language}/404.tsx" /* webpackChunkName: "component---src-pages-languages-json-language-404-tsx" */),
  "component---src-pages-languages-json-language-about-ethos-tsx": () => import("./../../../src/pages/{languagesJson.language}/about/ethos.tsx" /* webpackChunkName: "component---src-pages-languages-json-language-about-ethos-tsx" */),
  "component---src-pages-languages-json-language-about-index-tsx": () => import("./../../../src/pages/{languagesJson.language}/about/index.tsx" /* webpackChunkName: "component---src-pages-languages-json-language-about-index-tsx" */),
  "component---src-pages-languages-json-language-about-mission-tsx": () => import("./../../../src/pages/{languagesJson.language}/about/mission.tsx" /* webpackChunkName: "component---src-pages-languages-json-language-about-mission-tsx" */),
  "component---src-pages-languages-json-language-about-pedagogy-tsx": () => import("./../../../src/pages/{languagesJson.language}/about/pedagogy.tsx" /* webpackChunkName: "component---src-pages-languages-json-language-about-pedagogy-tsx" */),
  "component---src-pages-languages-json-language-academics-tsx": () => import("./../../../src/pages/{languagesJson.language}/academics.tsx" /* webpackChunkName: "component---src-pages-languages-json-language-academics-tsx" */),
  "component---src-pages-languages-json-language-achievements-tsx": () => import("./../../../src/pages/{languagesJson.language}/achievements.tsx" /* webpackChunkName: "component---src-pages-languages-json-language-achievements-tsx" */),
  "component---src-pages-languages-json-language-cocurricular-tsx": () => import("./../../../src/pages/{languagesJson.language}/cocurricular.tsx" /* webpackChunkName: "component---src-pages-languages-json-language-cocurricular-tsx" */),
  "component---src-pages-languages-json-language-contact-tsx": () => import("./../../../src/pages/{languagesJson.language}/contact.tsx" /* webpackChunkName: "component---src-pages-languages-json-language-contact-tsx" */),
  "component---src-pages-languages-json-language-enquire-tsx": () => import("./../../../src/pages/{languagesJson.language}/enquire.tsx" /* webpackChunkName: "component---src-pages-languages-json-language-enquire-tsx" */),
  "component---src-pages-languages-json-language-events-tsx": () => import("./../../../src/pages/{languagesJson.language}/events.tsx" /* webpackChunkName: "component---src-pages-languages-json-language-events-tsx" */),
  "component---src-pages-languages-json-language-gallery-tsx": () => import("./../../../src/pages/{languagesJson.language}/gallery.tsx" /* webpackChunkName: "component---src-pages-languages-json-language-gallery-tsx" */),
  "component---src-pages-languages-json-language-index-tsx": () => import("./../../../src/pages/{languagesJson.language}/index.tsx" /* webpackChunkName: "component---src-pages-languages-json-language-index-tsx" */),
  "component---src-pages-languages-json-language-legal-tsx": () => import("./../../../src/pages/{languagesJson.language}/legal.tsx" /* webpackChunkName: "component---src-pages-languages-json-language-legal-tsx" */),
  "component---src-pages-languages-json-language-testimonies-tsx": () => import("./../../../src/pages/{languagesJson.language}/testimonies.tsx" /* webpackChunkName: "component---src-pages-languages-json-language-testimonies-tsx" */),
  "component---src-pages-languages-json-language-yearlyplanner-tsx": () => import("./../../../src/pages/{languagesJson.language}/yearlyplanner.tsx" /* webpackChunkName: "component---src-pages-languages-json-language-yearlyplanner-tsx" */)
}

