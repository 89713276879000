import React, { FunctionComponent } from "react";
import { menuTree, MenuTreeItemComponent } from "./drop-down-menu-tree";
import styled from "styled-components";
import { useStringsContext } from "../../contexts/strings-context";

const menuTreeEntries = Object.entries(menuTree);

type CreateDropDownMenuParams = {
    MenuItem: MenuTreeItemComponent;
    SubMenuItem: MenuTreeItemComponent;
    DropDown: FunctionComponent;
};

type CreateDropDownMenu = (
    params: CreateDropDownMenuParams
) => FunctionComponent;

const SubMenu = styled.ul`
    margin: 0;
`;

export const createDropDownMenu: CreateDropDownMenu = ({
    MenuItem,
    SubMenuItem,
    DropDown
}) => () => {
    const strings = useStringsContext();
    return (
        <DropDown>
            {menuTreeEntries.map(([key, { children, to, href }]) => {
                return (
                    <>
                        <MenuItem
                            key={key}
                            text={strings.get(key)}
                            to={to}
                            href={strings.get(href ?? "") ?? href}
                        />
                        {typeof children !== "undefined" ? (
                            <SubMenu>
                                {Object.entries(children).map(
                                    ([key, { to, href }]) => {
                                        return (
                                            <SubMenuItem
                                                key={key}
                                                text={strings.get(key)}
                                                to={to}
                                                href={strings.get(href ?? "") ?? href}
                                            />
                                        );
                                    }
                                )}
                            </SubMenu>
                        ) : null}
                    </>
                );
            })}
        </DropDown>
    );
};
