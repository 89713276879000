import React from "react";
import styled from "styled-components";
import { graphql, PageProps } from "gatsby";
import {Navbar} from "./navbar";
import MobileNavBar from "./mobileNavbar";
import Footer from "./footer";

const LayoutStyle = styled.div`
    height: 100%;
    width: 100%;
`;

export const query = graphql`
    fragment LayoutStringsFragment on Strings {
        varqaschool
        home
        about
        admission
        onlineEnquiry
        achievements
        cocurricular
        academics
        events
        yearlyplanner
        facilities
        testimonies
        gallery
        contact
        legal
        privacy
    }
`;


export const Layout: React.FC<PageProps> = ({ children }) => {
    return (
        <>
            <Navbar/>
            <LayoutStyle>
                {children}
            </LayoutStyle>
            <Footer/>
        </>
    );
};