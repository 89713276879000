import React, { useContext, createContext, FunctionComponent } from "react";
import { PageProps } from "gatsby";

const initialContext = new Map<string, string>();

const StringsContext = createContext(initialContext);

export const useStringsContext = (): Map<string, string> =>
    useContext(StringsContext);

type WithStringsContext = (
    strings: Record<string, string>
) => (Component: FunctionComponent<PageProps>) => FunctionComponent<PageProps>;

export const withStringsContext: WithStringsContext = (strings) => (
    Component
) => (props) => (
    <StringsContext.Provider value={new Map(Object.entries(strings ?? {}))}>
        <Component {...props} />
    </StringsContext.Provider>
);
