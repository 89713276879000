import React from "react";
import { usePageContext } from '../contexts/page-context';
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

export interface LocalizedLinkProps {
    href?: string;
    children: React.ReactNode | undefined;
    className?: string;
    ignoreAnalytics?: boolean;
    noLanguage?: boolean;
    languageOverride?: string;
    target?: string;
}

// eslint-disable-next-line complexity
const LocalizedLink: React.FunctionComponent<LocalizedLinkProps> = ({ href, children, className, ignoreAnalytics, noLanguage, languageOverride, target }) => {
    const { language } = usePageContext();

    if (!href) {
        return <a className={className}>{children}</a>
    }

    if (href.startsWith("/")) {
        let languageURL;

        languageURL = ("/" + (languageOverride ?? language) + href);
        if (languageURL.startsWith("/en/")) {
            languageURL = languageURL.slice(3);
        }

        if (noLanguage === true) {
            languageURL = href;
        }

        return <Link className={className} to={languageURL}>{children}</Link>;
    }
    else if (ignoreAnalytics === true) {
        return <a className={className}>{children}</a>;
    }
    else {
        return <OutboundLink className={className} href={href} target={target}>{children}</OutboundLink>;
    }
};

export default LocalizedLink;