import React, { useContext, Fragment, FunctionComponent } from "react";
import { PageProps } from "gatsby";

export const PageContext = React.createContext({});

export const usePageContext = (): Record<string, string> =>
    useContext(PageContext);

type WithPageContext = (
    Component: FunctionComponent<PageProps>
) => FunctionComponent<PageProps>;

export const withPageContext: WithPageContext = (Component = Fragment) => (props) => (
    <PageContext.Provider value={props.pageContext}>
        <Component {...props} />
    </PageContext.Provider>
);
