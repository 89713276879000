import { DefaultTheme } from "styled-components";

type CreateTheme = (params: { strings: Map<string, string> }) => DefaultTheme;

const palette: DefaultTheme["palette"] = {
    lightGold: {
        main: "#F8F5F0",
        contrastText: "#BA884B"
    },
    white: {
        main: "#FFFFFF",
        contrastText: "#000000"
    },
    paleYellow: {
        main: "#FFFEF0",
        contrastText: "#955736"
    },
    softGreen: {
        main: "#FFFFFF",
        contrastText: "#9eb15f"
    },
    plumPurple: {
        main: "#FFFFFF",
        contrastText: "#9d4b83"
    },
    gradient: {
        main: "#FFFFFF",
        contrastText: "#f5b43b"
    },
}

export const createTheme: CreateTheme = ({ strings }) => ({
    fontSerif: strings.get("fontSerif") ?? '"adobe-garamond-pro", serif',
    fontSans: strings.get("fontSans") ?? '"solitaire-mvb-pro", sans-serif',
    fontDidot: strings.get("fontDidot") ?? '"linotype-didot", serif',
    direction: strings.get("direction") ?? "ltr",
    palette
});
