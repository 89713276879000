import styled from "styled-components";
import LocalizedLink from "../components/LocalizedLink";
import React from "react";
import { useOutsideAlert } from "../hooks/outsideAlert";
import { createDropDownMenu } from "./drop-down-menu";
import { createMenuItem } from "./menu-item";
import { useStringsContext } from "../contexts/strings-context";

const NavBarNav = styled.ul`
    max-width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    margin-block-start: 0;
    margin-block-end: 0;
`;

const NavBar = styled.nav`
    height: 50px;
    background-color: transparent;
`;

const Navitem = styled.nav`
    width: calc(60px * 1.6);
    display: flex;
    align-items: center;
`;

const MenuBar = styled.header`
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        position: absolute;
        top: 3rem;
        left: 2rem;
        z-index: 100;
`;

const IconButton = styled.a<{ open: boolean }>`
    cursor: pointer;
    background: transparent;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 1.7rem;
    outline: thin;
    z-index: 11;
    :hover {
        filter: none;
    }
    div {
        width: 1.8rem;
        height: 0.14rem;
        background: ${({ open }) => (open ? "white" : "white")};
        border-radius: 10px;
        transform-origin: 2px;
        transition: opacity 300ms, transform 300ms;
        :first-child {
            transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
        }
        :nth-child(2) {
            opacity: ${({ open }) => (open ? "0" : "1")};
            transform: ${({ open }) =>
        open ? "translateX(-20px)" : "translateX(0)"};
        }
        :nth-child(3) {
            transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
        }
    }
`;

const MenuItem = createMenuItem(styled.li`
    margin-bottom: 1rem;
    list-style-type: none;
    padding-left: 2rem;
    transition: background 500ms;
    padding-top: 1rem;
    a {
        text-decoration: none;
        color: #f5f5f5;
        font-size: 1.3rem;
        :hover {
            color: #BA884B;
        }
    }
    :first-child {
        padding-top: 2rem;
    }
`);

const SubMenuItem = createMenuItem(styled.li`
    margin-bottom: 0;
    list-style-type: none;
    transition: background 500ms;
    padding: 0;
    color: #ffffff;
    line-height: 2rem;
    a {
        text-decoration: none;
        color: #ffffff;
    }
`);

const DropDown = styled.ul`
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background: rgba(48, 47, 47, 0.699);
    border-radius: 10px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(141, 139, 139, 0.616);
    margin: 3rem 0 0 -2rem;
    padding-bottom: 2rem;
    width: 20rem;
    height: 100%;
    min-height: 83vh;
`;


const Navbar: React.FC = (props) => {
    return (
        <NavBar>
            <NavBarNav>{props.children}</NavBarNav>
        </NavBar>
    );
};


const NavItem: React.FC = (props) => {
    const { visible, setVisible, ref } = useOutsideAlert();
    return (
        <>
            <Navitem ref={ref}>
                <IconButton
                    open={visible}
                    onClick={() => setVisible((value) => !value)}
                >
                    <div />
                    <div />
                    <div />
                </IconButton>
                {visible && props.children}
            </Navitem>
        </>
    );
};

const DropDownMenu = createDropDownMenu({
    DropDown,
    MenuItem,
    SubMenuItem
});

const MobileNavBar: React.FC = () => {
    const strings = useStringsContext();
    return (
        <>
            <MenuBar>
                <Navbar>
                    <NavItem>
                        <DropDownMenu />
                    </NavItem>
                </Navbar>
            </MenuBar>
        </>
    );
};
export default MobileNavBar;
