import React, { FunctionComponent } from "react";
import { ThemeProvider } from "styled-components";
import { GlobalStyle } from "./global-style";
import { createTheme } from "./theme";
import { graphql, PageProps } from "gatsby";
import { useStringsContext } from "../contexts/strings-context";

type WithTheme = (
    Component: FunctionComponent<PageProps>
) => FunctionComponent<PageProps>;

export const withTheme: WithTheme = (Component) => (props) => {
    const strings = useStringsContext();
    const theme = createTheme({ strings });
    return (
        <ThemeProvider theme={theme}>
            <GlobalStyle />
            <Component {...props} />
        </ThemeProvider>
    );
};

export const query = graphql`
    fragment ThemeStringFragment on Strings {
        fontSerif
        fontSans
        fontDidot
        direction
    }
`;