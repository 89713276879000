import { MenuTreeItemComponent } from "./drop-down-menu/drop-down-menu-tree";
import React, { FunctionComponent } from "react";
import { Link } from "gatsby";
import { OutboundLink } from 'gatsby-plugin-google-gtag';

type CreateMenuItem = (Component: FunctionComponent) => MenuTreeItemComponent;

export const createMenuItem: CreateMenuItem = (Component) => ({ to, href, text }) => (
    <Component>
        {typeof to !== "undefined"
            ? <Link to={to}>{text}</Link>
            : <OutboundLink href={href}>{text}</OutboundLink>
        }
    </Component>
);
