import { FunctionComponent } from "react";

type MenuTreeItem = {
    text?: string;
    to?: string;
    href?: string;
    children?: MenuTree;
};

type MenuTree = Record<string, MenuTreeItem>;

export type MenuTreeItemComponent = FunctionComponent<MenuTreeItem>;

export const menuTree: MenuTree = {
    home: {
        to: "/"
    },
    about: {
        href: "/about"
    },
    admission: {
        href: "https://forms.gle/LmerSvGpxfAWgrBfA"
    },
    onlineEnquiry: {
        to: "/enquire"
    },
    cocurricular: {
        to: "/cocurricular"
    },
    achievements: {
        to: "/achievements"
    },
    academics: {
        to: "/academics"
    },
    events: {
        to: "/events"
    },
    yearlyplanner: {
        to: "/yearlyplanner"
    },
    testimonies: {
        to: "/testimonies"
    },
    gallery: {
        to: "/gallery"
    },
    contact: {
        to: "/contact"
    }
};
